@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'ArvoRegular';
    src: url('./fonts/Arvo-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArvoItalic';
    src: url('./fonts/Arvo-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'ArvoBold';
    src: url('./fonts/Arvo-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family:"orpheuspro";
    src:url("https://use.typekit.net/af/003616/00000000000000007735ebd7/30/l?subset_id=2&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/003616/00000000000000007735ebd7/30/d?subset_id=2&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/003616/00000000000000007735ebd7/30/a?subset_id=2&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}
    
@font-face {
    font-family:"orpheuspro";
    src:url("https://use.typekit.net/af/aeffc0/00000000000000007735ebd6/30/l?subset_id=2&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/aeffc0/00000000000000007735ebd6/30/d?subset_id=2&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/aeffc0/00000000000000007735ebd6/30/a?subset_id=2&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}
    
@font-face {
    font-family:"orpheuspro";
    src:url("https://use.typekit.net/af/4f5611/00000000000000007735ebd5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/4f5611/00000000000000007735ebd5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/4f5611/00000000000000007735ebd5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}
          
body {
  margin: 0;
  background-color: #EFEBE4;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'orpheuspro', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
